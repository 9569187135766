/* Main CSS 8*/
body {
    margin: 0;
    font-family: "Roboto", sans-serif;
}

:root {
    /* colors #0062C5 #282938 #02A3BE #0C2249*/
    --primary: #0062C5;
    --heading-color: #041430;
    --brown: #A6937D;
    --bg-shade: #fafaff;
    --blue: #0045c50c;
    --darkblue: #0C2249;
    --black: #040509;
    --white: #ffffff;
}

.btn-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    margin-bottom: 50px;
}

.btn {
    font-family: "Roboto", sans-serif;
    display: inline-block;
    padding: 25px 60px;
    width: 450px;
    font-weight: 800;
    font-size: 30px;
    line-height: 24px;
    cursor: pointer;
    border-radius: 120px;

}

.btn-primary {
    color: var(--white);
    background-color: var(--primary);
    border: 1px solid var(--primary);
}

.btn-primary:hover {
    color: var(--primary);
    background-color: var(--white);
    border-bottom: 5px solid var(--primary);
}

@media only screen and (max-width: 768px) {
    .btn {
        font-size: 18px;
        padding: 15px 30px;
        width: 250px;
    }
  }

h1 {
    font-size: 50px;
}

p {
    font-size: 25px;
}

.small-text p {
    font-size: 15px;
}

/* Navbar Style */
.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    box-shadow: 0px 5px 80px 0 rgba(0,0,0, 0.1);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
    background-color: var(--white);
}

.navbar_items > ul {
    margin-left: 20px;
    margin-right: 1.5rem;
    list-style: none;
    display: flex;
    align-items: flex-start;
    gap: 40px;
}

.navbar-content {
    color: var(--black);
    cursor: pointer;
    white-space: nowrap; 
    overflow: hidden; 
    text-overflow: ellipsis; 
    max-width: 100px;
    text-decoration: none;
}

.navbar-img {
    width: 14vw;
    height: auto;
}

@media (min-width: 768px) {
    .navbar-img {
        width: 100px; 
    }
}

.navbar-active {
    color: var(--primary);
}

/* Main Section Style */
.main-section {
    position: relative;
  }
  
  .main-img {
    width: 100%;
    height: auto;
    display: block;
  }
  
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.70); 
  }
  
  .main-content-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    z-index: 1;
    color: white; 
    animation: fadeIn 2.6s ease forwards;
  }
  
@keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
}

@media (min-width: 2000px) {
    .main-content-box {
      font-size: 2.5rem; /* Adjust font size as needed */
      top: 45%; /* Adjust positioning */
    }
  }

.scroll-arrow {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    animation: pulse 2s infinite;
  }

.scroll-arrow:hover {
    cursor: pointer;
}
  
.arrow {
    border: solid var(--white);
    border-width: 0 5px 5px 0;
    display: inline-block;
    padding: 7px;
    transform: rotate(45deg);
}
  
@keyframes pulse {
    0% {
      transform: translateX(-50%) scale(1);
    }
    50% {
      transform: translateX(-50%) scale(1.2);
    }
    100% {
      transform: translateX(-50%) scale(1);
    }
}
  
@media (max-width: 1660px) {
    .scroll-arrow {
      display: none;
    }
}

/* Services Style */
.title-container {
    padding: 20px 0;
    text-align: center;
}

.title {
    color: var(--black);
    font-size: 45px;
}

.services-section {
    margin-bottom: 40px;
    text-align: center; 
    background-color: var(--blue);
    overflow: hidden; 
}

.services-list {
    display: flex;
    justify-content: center; 
    flex-wrap: wrap;
    gap: 20px;
    padding: 20px; 
    margin-bottom: 80px;
}

.services-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 200px; 
    border-radius: 10px;
    border: solid 1px var(--primary);
    background-color: var(--white);
    padding: 20px;
    
}

.services-card-img {
    width: 100px;
    height: 100px;
    overflow: hidden;
}

.services-card-img img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: inherit;
}

.list-title {
    margin-top: 15px;
}

/* Gallery Style */
.logos-section {
    margin: 0;
    margin-bottom: 50px;
    padding: 0;
    box-sizing: border-box;
}

.logos {
    box-sizing: border-box;
    overflow: hidden;
    padding: 40px 0;
    white-space: nowrap;
    cursor: pointer;
}

.logos:active {
    cursor: grabbing; 
}

.logos-slide {
    display: flex;
    animation: scroll 60s linear infinite;
}

.logos-slide img {
    height: 400px;
    margin: 0px 15px;
}

@keyframes slide {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(-80%);
    }
}

/* Contact Form Style */
.parent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--blue);
}

.tab-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 10px;
    border-radius: 25px;
    background-color: #ffffff;
    width: 65%;
    margin-bottom: 60px;
}

.tab-box {
    width:  70%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-bottom: solid 2px var(--black);
    font-size: 27px;
    font-weight: 600;
    position: relative;
}

.tab-box .tab-btn {
    font-size: 27px;
    font-weight: 600;
    color: var(--black);
    background: none;
    border: none;
    padding: 15px;
    cursor: pointer;
}

.tab-box .tab-btn.active {
    color: var(--primary);
}

.tab-content-box {
    padding: 20px;
}

.tab-content-box .tab-content{
    display: none;
    animation: fadeInAnim .5s ease;
}

.tab-content-box .tab-content h2{
    margin-bottom: 10px;
}

.line{
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 50%;
    height: 5px;
    background-color: var(--primary);
    border-radius: 20px;
    transition: all .2s ease-in-out;
}

.tab-content-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    
}

.tab-content {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: repeat(5, auto);
    width: 100%;
    row-gap: 32px;
}

.contact-container {
    display: grid;
    column-gap: 32px;
    row-gap: 32px;
}

.contact-label {
    font-family: "Roboto";
    font-size: 22px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    width: 100%;
}

.contact-label textarea {
    resize: none;
}

.contact-input {
    display: flex;
    font-family: "Roboto";
    font-size: 22px;
    padding: 10px;
    align-items: center;
    align-self: stretch;
    border-radius: 8px;
    border: 1px solid var(--primary);
}

.checkbox-label {
    font-family: "Roboto";
    font-size: 18px;
}



.contact-form-btn.submitting span {
    animation: fadeOut 1.35s infinite;
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

/* About Style */

.about-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

iframe {
    border-radius: 15px;
    margin-bottom: 60px;
}

/* Footer Starts */
.footer-container {
    display: flex;
    padding: 50px 20px;
    flex-direction: column;
    align-items: center;
    background: var(--bg-shade);
    
  }
  .footer-link-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    
  }
  .footer-social-icon > ul {
    list-style: none;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 16px;
    flex-shrink: 0;
    text-decoration: none;
  }

  .footer-links{
    color: var(--black);
    text-decoration: none;
    cursor: pointer;
    font-weight: 500;
  }

  .phone-link {
    color: var(--black);
    text-decoration: none;
    cursor: pointer;
  }

  .phone-link:hover {
    color: var(--primary);
  }

  .footer-links:hover{
    color: var(--primary);
  }

  .social-icon {
    width:20px;
    height:20px;
  }

  /* terms and private policy style */
  .terms-content {
    margin: 50px;
  }

  .terms-content p {
    font-size: 20px;
  }

  /* success style */
  .success-section {
    padding: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media only screen and (max-width: 768px) {
    .btn-container{
        margin-top: 25px;
        margin-bottom: 25px;
    }
    .main-content-box {
      width: 90%;
    }
    .main-content{
        margin-top: 10vh;
    }
    .main-content p {
        font-size: 15px;
    }
    .main-content h1 {
        font-size: 25px;
    }
    .title {
        font-size: 35px;
    }
    .tab-container {
        width: 90%;
    }

    iframe {
        width: 380px;
    }

    .footer-container{
        padding: 0px 5px;
    }
    .navbar_items > ul {
        gap: 17px;
        font-size: 14px;
        margin-right: 2.5rem;
    }

    .footer-link-container{
        justify-content: center;
    }
    .logos-slide img {
        height: 270px;
        margin: 0px 10px;
    }
    .services-card {
        width: 100px;
    }
    p{
        font-size: 18px;
    }
  }